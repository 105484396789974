
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmTabsNav from '@/components/shared/TmTabsNav.vue'
import WorkspaceUsersFaq from '@/components/pages/admin/workspace/users/WorkspaceUsersFaq.vue'
import type { Tab } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    PageContent,
    TmButton,
    TmEmptyState,
    TmTabsNav,
    WorkspaceUsersFaq,
  },
  props: {
    subAccounts: {
      type: Boolean,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openModal } = useModals()
    const selected = ref<any[]>([])
    const tabValue = ref()
    const tabs = ref<Tab[]>([
      {
        name: 'active',
        label: 'Active',
        to: { name: 'base.admin.users.active' },
        counter: 4,
      },
      {
        name: 'closed',
        label: 'Closed',
        counter: 0,
      },
      {
        name: 'invited',
        label: 'Invited',
        to: { name: 'base.admin.users.invited' },
        counter: 2,
      },
    ])

    return {
      isEmptyMode,
      openModal,
      tabs,
      tabValue,
      selected,
    }
  },
})
